import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSystemState } from "../../../@types/settings/reduxSystemState";

const initialState: ReduxSystemState = {
  isOpen: false,
  message: "Действие успершно завершено",
  severity: "success",
};

const systemStateSlice = createSlice({
  name: "systemState",
  initialState,
  reducers: {
    setToastIsOpen: (state, isOpen: PayloadAction<boolean>) => {
      state.isOpen = isOpen.payload;
    },
    setToastSeverity: (state, severity: PayloadAction<AlertColor>) => {
      state.severity = severity.payload;
    },
    setToastMessage: (state, message: PayloadAction<string>) => {
      state.message = message.payload;
    },
  },
});

export const { setToastIsOpen, setToastSeverity, setToastMessage } =
  systemStateSlice.actions;

export const systemStateActions = {
  setToastIsOpen,
  setToastSeverity,
  setToastMessage,
};

export const systemStateReducer = systemStateSlice.reducer;
