interface SidebarLink {
  link: string,
  name: string
}

export const sidebarLinks: SidebarLink[] = [
  {
    link: 'referrals',
    name: 'Рефералы'
  },
  {
    link: 'referral-types',
    name: 'Реферальные типы'
  },
  {
    link: 'statistic',
    name: 'Статистика'
  },
  {
    link: 'referral-bonus',
    name: 'Реферальные бонусы'
  },
  {
    link: 'users',
    name: 'Пользователи'
  }
]
