import { SystemState } from "../../@types/settings/systemState";
import { api } from "../api-client";

export function patchSystemSettings(status: number) {
  return api.patch<any, unknown | Error>(
    `${process.env.REACT_APP_API_URL}ReferralSetting/update_referral_setting/${status}`,
    {}
  );
}

export function getSettings() {
  return api.get<SystemState>(
    `${process.env.REACT_APP_API_URL}ReferralSetting/get_referral_setting`
  );
}
