import {api} from "../api-client"
import {Bonus} from "../../@types/referral-bonus/bonus"
import {CreateReferralBonus, UpdateReferralBonus} from "../../@types/referral-bonus/createReferralBonus"

export const getReferralBonuses = () => {
  return api.get<Bonus[]>(`${process.env.REACT_APP_API_URL}referralbonus/get_referral_bonuses`);
}

export const deleteReferralBonus = (id: string) => {
  return api.delete(`${process.env.REACT_APP_API_URL}referralbonus/delete_referral_bonus/${id}`)
}

export const createReferralBonus = (referralBonus: CreateReferralBonus) => {
  return api.post(`${process.env.REACT_APP_API_URL}referralbonus/add_referral_bonus`, referralBonus);
}

export const updateReferralBonus = (referralBonus: UpdateReferralBonus) => {
  return api.put(`${process.env.REACT_APP_API_URL}referralbonus/update_referral_bonus`, referralBonus);
}

export const getReferralBonus = (id: string) => {
  return api.get<Bonus>(`${process.env.REACT_APP_API_URL}referralbonus/get_referral_bonus_by_id/${id}`);
}
