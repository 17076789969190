import * as React from 'react';
import MaterialPagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { paginationSettings } from "../../constants/pagination";


interface Props {
    page: number,
    totalCount: number,
    changePage: Function
}

export default function Pagination({ page, totalCount, changePage }: Props) {
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        setTotalPages(totalCount <= paginationSettings.take ? 1 : Math.ceil(totalCount / paginationSettings.take))
    }, [totalCount])

    const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => changePage(value);

    return (
        <>
            {typeof (totalPages) != "undefined" && <Stack spacing={2}>
                <MaterialPagination count={totalPages} page={page} onChange={onChangePage} variant="outlined" color="primary" />
            </Stack>}
        </>
    );
}
