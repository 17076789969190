import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../../@types/authorization/authState";

const initialState: AuthState = {
    isLogin: localStorage.getItem('access_token') ? true : false
};

const authorizationSlice = createSlice({
    name: "authorization",
    initialState,
    reducers: {
        setIsLogin: (state, isLogin: PayloadAction<boolean>) => {
            state.isLogin = isLogin.payload;
        },
    },
});

export const {
    setIsLogin,
} = authorizationSlice.actions;

export const authorizationActions = {
    setIsLogin,
};

export const authorizationReducer = authorizationSlice.reducer;
