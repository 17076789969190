import { Alert, SlideProps, Snackbar } from "@mui/material";
import { useTypedSelector } from "../../__store/hooks/typedSelector";
import { useActions } from "../../__store/hooks/useAction";

export const Toast = () => {
  const { setToastIsOpen } = useActions();
  const systemState = useTypedSelector((s) => s.systemState);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToastIsOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={systemState.isOpen}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={systemState.severity}
          sx={{ width: "100%" }}
        >
          {systemState.message}
        </Alert>
      </Snackbar>
    </>
  );
};
