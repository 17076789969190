import { ReferralType } from '../../@types/referral-type/referralType';
import { RewardHistory } from '../../@types/referral/rewardHistory';
import { ReferralUser, ReferralUsers } from '../../@types/referral/referralUser';
import {api} from '../api-client';

export function getAll(
  page: number,
  take: number,
  queryParametes: string
) {
  return api.get<ReferralUsers>(`${process.env.REACT_APP_API_URL}ReferralUser?take=${take}&page=${page}&${queryParametes}`);
}

export function patch(form: any) {
  return api.patch<any, unknown | Error>(`${process.env.REACT_APP_API_URL}ReferralUser`, form);
}

export function getById(id: string) {
  return api.get<ReferralUser>(`${process.env.REACT_APP_API_URL}ReferralUser/get_by_id?id=${id}`);
}

export function getSumRewards(id: string) {
  return api.get<any>(`${process.env.REACT_APP_API_URL}api/History/sum/${id}`);
}

export function getRewardsHistory(id: string, query: string) {
  return api.get<RewardHistory[]>(`${process.env.REACT_APP_API_URL}api/History?id=${id}&page=1&take=10000&${query}`);
}

export function disableUser(form: any) {
  return api.patch<any, unknown | Error>(`${process.env.REACT_APP_API_URL}ReferralUser/disable`, form);
}

export function getReferralTypes() {
  return api.get<ReferralType[]>(`${process.env.REACT_APP_API_URL}ReferralType/get_referral_types`);
}
