import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticFilters } from "../../../@types/statistic/statisticFilters";

const defaultFromDate = new Date("2022-06-01T00:00:00");
const defaultDateTo = new Date(Date.now());
const stringEmpty = "";

const initialState: StatisticFilters = {
  usersDateFrom: defaultFromDate,
  usersDateTo: defaultDateTo,
  usersTypeId: stringEmpty,

  rewardsDateFrom: defaultFromDate,
  rewardsDateTo: defaultDateTo,
  rewardsTypeId: stringEmpty,

  statisticDateFrom: defaultFromDate,
  statisticDateTo: defaultDateTo,
  statisticTypeId: stringEmpty,
  isActive: true,
};

const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setRewardsFromDate: (state, date: PayloadAction<Date>) => {
      state.rewardsDateFrom = date.payload;
    },
    setRewardsToDate: (state, date: PayloadAction<Date>) => {
      state.rewardsDateTo = date.payload;
    },
    setUsersFromDate: (state, date: PayloadAction<Date>) => {
      state.usersDateFrom = date.payload;
    },
    setUsersToDate: (state, date: PayloadAction<Date>) => {
      state.usersDateTo = date.payload;
    },
    setStatisticFromDate: (state, date: PayloadAction<Date>) => {
      state.statisticDateFrom = date.payload;
    },
    setStatisticToDate: (state, date: PayloadAction<Date>) => {
      state.statisticDateTo = date.payload;
    },
    setStatisticTypeId: (state, typeId: PayloadAction<string>) => {
      state.statisticTypeId = typeId.payload;
    },
    setRewardsTypeId: (state, typeId: PayloadAction<string>) => {
      state.rewardsTypeId = typeId.payload;
    },
    setUsersTypeId: (state, typeId: PayloadAction<string>) => {
      state.usersTypeId = typeId.payload;
    },
    setIsActive: (state, isActive: PayloadAction<boolean>) => {
      state.isActive = isActive.payload;
    },
  },
});

export const {
  setRewardsFromDate,
  setRewardsToDate,
  setUsersFromDate,
  setUsersToDate,
  setStatisticFromDate,
  setStatisticToDate,
  setStatisticTypeId,
  setRewardsTypeId,
  setUsersTypeId,
  setIsActive,
} = statisticSlice.actions;

export const statisticActions = {
  setRewardsFromDate,
  setRewardsToDate,
  setUsersFromDate,
  setUsersToDate,
  setStatisticFromDate,
  setStatisticToDate,
  setStatisticTypeId,
  setRewardsTypeId,
  setUsersTypeId,
  setIsActive,
};

export const statisticReducer = statisticSlice.reducer;
