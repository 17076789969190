import {ChangeEvent, MouseEventHandler, useState} from "react"
import {ReferralBonusCreateView} from "./view"
import {ReferralType} from "../../../@types/referral-type/referralType"
import {CreateReferralBonus} from "../../../@types/referral-bonus/createReferralBonus"

interface ReferralBonusCreateUpdateProps {
  isLoading: boolean;
  commonError: string;
  referralTypes: ReferralType[];
  referralBonus: CreateReferralBonus;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  setReferralBonus: Function;
}

export const ReferralBonusCreateUpdate = (props: ReferralBonusCreateUpdateProps) => {

  const [nameErrors, setNameErrors] = useState("");
  const [levelErrors, setLevelErrors] = useState("");

  const {isLoading, referralBonus, referralTypes, handleSubmit, commonError, setReferralBonus} = props;

  const hasErrors = () => {
    return !!nameErrors || !!levelErrors || !!commonError;
  };

  const isCreateBtnDisabled = () => {
    return hasErrors() || !referralBonus.referralTypeId;
  };

  const setReferralBonusName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setReferralBonus({...referralBonus, name: e.target.value});
    if (!name || name.length < 2) {
      setNameErrors("Название должно содержать хотя бы 2 символа");
    } else {
      setNameErrors("");
    }
  };

  const setReferralBonusLevel = (e: ChangeEvent<HTMLInputElement>) => {
    let value = +e.target.value;
    if (Number.isInteger(value) && value <= 3) {
      setLevelErrors("");
      setReferralBonus({...referralBonus, level: value});
    }

    if (value === 0) {
      setLevelErrors("Уровень должен быть от 1 до 3");
    }
  };

  const setReferralBonusValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (Number.isInteger(value)) {
      setReferralBonus({...referralBonus, value: value});
    }
  };

  const setReferralTypeId = (e: ChangeEvent<HTMLInputElement>) => {
    setReferralBonus({...referralBonus, referralTypeId: e.target.value});
  };

  return <ReferralBonusCreateView
    isLoading={isLoading}
    hasErrors={hasErrors}
    nameErrors={nameErrors}
    levelErrors={levelErrors}
    commonError={commonError}
    referralTypes={referralTypes}
    referralBonus={referralBonus}
    setReferralBonusName={setReferralBonusName}
    setReferralBonusLevel={setReferralBonusLevel}
    setReferralBonusValue={setReferralBonusValue}
    setReferralTypeId={setReferralTypeId}
    handleSubmit={handleSubmit}
    isCreateBtnDisabled={isCreateBtnDisabled}
  />
}
