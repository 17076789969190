import { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { TextField } from "@mui/material";
// import { zam } from "../../assets/icons/zam.png";
import "./index.css"
import { Authorization } from "../../@types/authorization/auth";
import { getToken } from "../../api/authorization";
import { AuthResponse } from "../../@types/authorization/authResponse";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../__store/hooks/useAction";
import { useTypedSelector } from "../../__store/hooks/typedSelector";

export const Auhorization = () => {
    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();
    const navigate = useNavigate();
    const state = useTypedSelector((s) => s.authorization);

    const {
        setIsLogin
    } = useActions();

    useEffect(() => {

    }, []);

    function Login() {
        const form: Authorization = {
            password: password as string,
            username: username as string,
            refreshToken: ""

        }
        getToken(form).then((res: any) => {
            const mapData = res.data as AuthResponse
            localStorage.setItem('access_token', mapData.access_token);
            localStorage.setItem('refresh_token', mapData.refresh_token);
            navigate(`/Referrals`, { replace: true });
            setIsLogin(true)
        })

    }

    return (
        <>
            <div className="general-container">
                <Card sx={{ maxWidth: 300 }}>
                    <CardContent className="auth-card">
                        {/* <img src={zam} /> */}
                        <TextField
                            id="outlined"
                            sx={{ ml: 3, mr: 3, mt: 3 }}
                            label="Логин"
                            onChange={e => setUserName(e.target.value)}
                        />
                        <TextField
                            sx={{ mt: 3, ml: 3, mr: 3 }}
                            id="outlined-password-input"
                            label="Пароль"
                            type="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                        />
                    </CardContent>
                    <CardActions className="auth-card">
                        <Button sx={{ mb: 2 }} variant="contained" onClick={e => Login()}>Войти</Button>
                    </CardActions>
                </Card></div>
        </>
    );
};
