import {ReferralType} from "../../../@types/referral-type/referralType"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "redux"
import {deleteReferralType, getReferralTypes} from "../../../api/referral-type"

interface ReferralTypeState {
  referralTypes: ReferralType[],
  error: string | null,
  isLoading: boolean,
  page: number,
  take: number
}

const initialState: ReferralTypeState = {
  referralTypes: [],
  error: '',
  isLoading: false,
  page: 1,
  take: 1000
}

const referralTypeSlice = createSlice({
  name: 'referral-type',
  initialState,
  reducers: {
    fetchReferralTypesRequest: (state) => {
      state.referralTypes = [];
      state.isLoading = true;
      state.error = null;
    },
    fetchReferralTypesSuccess: (state, referralTypes: PayloadAction<ReferralType[]>) => {
      state.isLoading = false;
      state.error = null;
      state.referralTypes = referralTypes.payload;
    },
    fetchReferralTypesError: (state) => {
      state.referralTypes = [];
      state.isLoading = false;
      state.error = "Что-то произошло не так";
    },
    setReferralTypesPage: (state, page: PayloadAction<number>) => {
      state.page = page.payload;
    },
    setLoadingState: (state) => {
      state.isLoading = true;
    }
  }
})

export const {
  fetchReferralTypesError,
  fetchReferralTypesRequest,
  fetchReferralTypesSuccess,
  setReferralTypesPage,
  setLoadingState
} = referralTypeSlice.actions

export const fetchReferralTypesRequestAsync = (page: number, take: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchReferralTypesRequest());
      getReferralTypes(page, take)
        .then(({data, error}) => {
          if (error) {
            dispatch(fetchReferralTypesError())
          } else {
            dispatch(fetchReferralTypesSuccess(data))
          }
        })
    } catch {
      dispatch(fetchReferralTypesError())
    }
  }
}

export const deleteReferralTypesRequestAsync = (state: ReferralTypeState, id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingState());
      deleteReferralType(id)
        .then(({error}) => {
          if (error) {
            dispatch(fetchReferralTypesError())
          } else {
            // @ts-ignore
            dispatch(fetchReferralTypesRequestAsync(state.page, state.take))
          }
        })
    } catch {
      dispatch(fetchReferralTypesError())
    }
  }
}

export const referralTypeActions = {
  fetchReferralTypesError,
  fetchReferralTypesRequestAsync,
  fetchReferralTypesRequest,
  fetchReferralTypesSuccess,
  setReferralTypesPage,
  deleteReferralTypesRequestAsync
};

export const referralReducer = referralTypeSlice.reducer;

