import { UsersModel } from "../../@types/users/ssoUser";
import { api } from "../api-client";

export function getAll(page: number, take: number, queryParametes: string) {
  return api.get<UsersModel>(
    `${process.env.REACT_APP_API_URL}ReferralUser/sso_users?take=${take}&page=${page}&${queryParametes}`
  );
}

export function post(form: any) {
  return api.post<any, unknown | Error>(
    `${process.env.REACT_APP_API_URL}ReferralUser`,
    form
  );
}
