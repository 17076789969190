import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Pagination from "../../../components/pagination/pagination";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { Filters } from "../../../components/filters";
import { SelectObject } from "../../../components/filters/types/selectObject";
import { paginationSettings } from "../../../constants/pagination";
import { UpdateReferralUserModal } from "../referral-update";
import { useNavigate } from "react-router-dom";
import { ReferralType } from "../../../@types/referral-type/referralType";
import { ReferralUsers } from "../../../@types/referral/referralUser";
import { getAll, getReferralTypes } from "../../../api/referral";
import { UserStatuses } from "../../../constants/statuses";

import "./index.css";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  justifyContent: "center",
  display: "flex",
  boxShadow: "none",
  marginTop: "2%",
}));

export const Referrals = () => {
  const [referrals, setReferrals] = useState<ReferralUsers>();
  const [selectCollection, setSelectCollection] = useState<SelectObject[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [id, setId] = useState<string>("");
  const [current, setCurrent] = useState<SelectObject[]>([]);
  const [queryParameters, setQueryParameters] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    (page > 1 || queryParameters) && fetchData(queryParameters);
  }, [page, queryParameters]);

  function fetchData(queryParameters: string) {
    queryParameters && setQueryParameters(queryParameters);
    getAll(page, paginationSettings.take, queryParameters).then(
      (users) => {
        if (users.data) {
          setReferrals(users.data as ReferralUsers);
          setTotalCount((users.data as ReferralUsers).totalCount);
        }
      }
    );

    getReferralTypes().then((types) => {
      const refTypes: ReferralType[] = types.data || []
      const mapTypes: SelectObject[] = refTypes
        ? refTypes.map((s) => ({ value: s.id, label: s.name }))
        : []; // no error
      setSelectCollection(mapTypes);
    });
  }


  const navigateToInfo = (id: string, event: any) => {
    event.preventDefault();
    navigate(`${id}`, { replace: true });
  };

  const openModal = (id: string, referralTypeId: string) => {
    setCurrent(
      selectCollection?.filter((x) => x.value === referralTypeId) ?? []
    );
    setId(id);
    setModalIsOpen(!modalIsOpen);
  };

  const changePage = (page: number) => {
    setPage(page);
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography className="title" variant="h5" gutterBottom component="div">
          <div className="title">Список рефералов</div>
        </Typography>
      </Breadcrumbs>
      {selectCollection && (
        <Filters
          searchId="phoneNumber"
          searchLabel="Поиск (тел/имя)"
          isSearch={true}
          isSelect={true}
          selectId="type"
          selectLabel="Тип"
          selectCollection={selectCollection}
          isOrderBy={true}
          orderByLabel="По убыванию (ZAM)"
          isFastFilter={true}
          fastFilterLabel="Статус (активный/не активный)"
          fastFilterId="status"
          updateFilters={setQueryParameters}
        />
      )}
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={3}>
              ФИО
            </Grid>
            <Grid className="media" item xs={2}>
              Телефон
            </Grid>
            <Grid className="media" item xs={2}>
              Статус
            </Grid>
            <Grid item xs={2}>
              Бонус
            </Grid>
            <Grid item xs={2} />
          </Grid>
          <List>
            {referrals &&
              referrals?.referralUsers?.map((referral) => (
                <ListItem disablePadding key={referral.id}>
                  <ListItemButton>
                    <Grid
                      justifyContent="space-between"
                      container
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        onClick={(e) => navigateToInfo(referral.id, e)}
                        item
                        xs={3}
                      >
                        {referral.fullName}
                      </Grid>
                      <Grid
                        className="media"
                        onClick={(e) => navigateToInfo(referral.id, e)}
                        item
                        xs={2}
                      >
                        {referral.phoneNumber}
                      </Grid>
                      <Grid
                        className="media"
                        onClick={(e) => navigateToInfo(referral.id, e)}
                        item
                        xs={2}
                      >
                        {
                          UserStatuses.find((x) => x.id == referral.status)
                            ?.value
                        }
                      </Grid>
                      <Grid
                        onClick={(e) => navigateToInfo(referral.id, e)}
                        item
                        xs={2}
                      >
                        {referral.sumRewards} ZAM
                      </Grid>
                      <Grid display="flex" justifyContent="end" item xs={2}>
                        <IconButton
                          color="primary"
                          aria-label="edit status"
                          component="span"
                          onClick={() =>
                            openModal(referral.id, referral.referralType.id)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <ListItemText />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </CardContent>
      </Card>
      {totalCount && (
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            <Item>
              <Pagination
                page={page}
                totalCount={totalCount}
                changePage={changePage}
              />
            </Item>
          </Stack>
        </Box>
      )}
      <UpdateReferralUserModal
        id={id}
        types={selectCollection}
        isOpen={modalIsOpen}
        handle={setModalIsOpen}
        current={current}
      />
    </>
  );
};
