import Stack from "@mui/material/Stack";
import { Button, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { ChangeEventHandler, MouseEventHandler } from "react";
import { ReferralType } from "../../../@types/referral-type/referralType";
import { CreateReferralBonus } from "../../../@types/referral-bonus/createReferralBonus";

interface ReferralBonusCreateViewProps {
  isLoading: boolean;
  hasErrors: Function;
  nameErrors: string;
  levelErrors: string;
  commonError: string;
  referralTypes: ReferralType[];
  referralBonus: CreateReferralBonus;
  setReferralBonusName: ChangeEventHandler<HTMLInputElement>;
  setReferralBonusLevel: ChangeEventHandler<HTMLInputElement>;
  setReferralBonusValue: ChangeEventHandler<HTMLInputElement>;
  setReferralTypeId: ChangeEventHandler<HTMLInputElement>;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  isCreateBtnDisabled: Function;
}

export const ReferralBonusCreateView = (
  props: ReferralBonusCreateViewProps
) => {
  const outlined = "outlined";

  const {
    isLoading,
    hasErrors,
    nameErrors,
    levelErrors,
    commonError,
    referralTypes,
    referralBonus,
    setReferralBonusName,
    setReferralBonusLevel,
    setReferralBonusValue,
    setReferralTypeId,
    handleSubmit,
    isCreateBtnDisabled,
  } = props;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const errors = hasErrors() && (
    <div>
      <Divider />
      {[nameErrors, levelErrors, commonError].join(", ")}
    </div>
  );

  const referralTypeSelect = referralTypes.map((rt) => {
    return (
      <MenuItem key={rt.id} value={rt.id}>
        {rt.name}
      </MenuItem>
    );
  });

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="column" spacing={2}>
          <div>
            <TextField
              label={"Название реферального бонуса"}
              required={true}
              variant={outlined}
              value={referralBonus.name}
              onChange={setReferralBonusName}
            />
          </div>

          <div>
            <TextField
              label={"Уровень реферального бонуса"}
              required={true}
              variant={outlined}
              value={referralBonus.level}
              onChange={setReferralBonusLevel}
            />
          </div>

          <div>
            <TextField
              label={"Значение реферального бонуса"}
              required={true}
              variant={outlined}
              value={referralBonus.value}
              onChange={setReferralBonusValue}
            />
          </div>

          <div>
            <TextField
              select
              value={referralBonus.referralTypeId}
              label="Реферальный тип"
              helperText="Выберeте значение"
              onChange={setReferralTypeId}
            >
              {referralTypeSelect}
            </TextField>
          </div>
          <div>
            <Button
              variant={outlined}
              onClick={handleSubmit}
              disabled={isCreateBtnDisabled()}
            >
              Создать
            </Button>
          </div>
        </Stack>

        {errors}
      </Stack>
    </>
  );
};
