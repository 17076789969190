import {ReferralType} from '../../@types/referral-type/referralType';
import {api} from '../api-client';
import {Error} from '../../@types/error'
import {CreateReferralType} from "../../@types/referral-type/createReferralType"

export const getReferralTypes = (page: number = 1, take: number = 10) => {
  return api.get<ReferralType[]>(`${process.env.REACT_APP_API_URL}referraltype/get_referral_types?page=${page}&take=${take}`);
}

export const createReferralType = (name: string) => {
  return api.post<CreateReferralType, unknown | Error>(`${process.env.REACT_APP_API_URL}referraltype/add_referral_type`, {name: name});
}

export const deleteReferralType = (id: string) => {
  return api.delete(`${process.env.REACT_APP_API_URL}referraltype/delete_referral_type/${id}`);
}

export const getReferralType = (id: string) => {
  return api.get<ReferralType>(`${process.env.REACT_APP_API_URL}referraltype/get_referral_type_by_id/${id}`);
}

export const updateReferralType = (referralType: object) => {
  return api.put(`${process.env.REACT_APP_API_URL}referraltype/update_referral_type`, referralType);
}
