import Dialog from "@mui/material/Dialog"
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material"

interface ConfirmationDialogProps {
  title: string;
  subTitle: string;
  declineText?: string;
  acceptText?: string;
  isOpened: boolean;
  handleAccept: () => void;
  handleDecline: () => void;
}

const initialConfirmationDialogProps: ConfirmationDialogProps = {
  handleAccept(): void {},
  handleDecline(): void {},
  title: 'Подтвердите действие',
  subTitle: 'Вы уверены?',
  declineText: 'Нет',
  acceptText: 'Да',
  isOpened: false
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  props = {...initialConfirmationDialogProps, ...props};

  const {title, subTitle, declineText, acceptText, isOpened, handleDecline, handleAccept} = props;

  return (
    <Dialog
      open={isOpened}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{subTitle}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDecline}>
          {declineText}
        </Button>
        <Button onClick={handleAccept}>
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

