import {useTypedSelector} from "../../../../__store/hooks/typedSelector"
import {ChangeEvent, useEffect} from "react"
import {useActions} from "../../../../__store/hooks/useAction"
import {useNavigate} from "react-router-dom"
import {ReferralTypeCreateUpdate} from "../index"

export const ReferralTypeCreate = () => {

  const {error, name, isLoading, isCreatedSuccessfully} = useTypedSelector(s => s.createRefferalType);

  const {createReferralTypeAsync, setReferralTypeName} = useActions();

  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReferralTypeName(event.target.value);
  }

  useEffect(() => {
    if (isCreatedSuccessfully) {
      navigate('/referral-types')
    }
  }, [isCreatedSuccessfully])

  const handleCreate = () => {
    createReferralTypeAsync(name);
  }

  return <ReferralTypeCreateUpdate
    isLoading={isLoading}
    error={error}
    handleChange={handleChange}
    handleSumbit={handleCreate}
    btnText={"Создать"}
    name={name}
  />
}
