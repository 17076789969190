import {DataGrid, GridColDef, GridRenderCellParams, GridValidRowModel} from "@mui/x-data-grid"
import {Button} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {useState} from "react"
import {DeleteForeverOutlined} from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import {ConfirmationDialog} from "../confirmation-dialog"

export interface GridDeleteConfirmationDialogProps {
  title: string;
  subTitle: string;
  declineText?: string;
  acceptText?: string;
  handleAccept: () => void;
  handleDecline: () => void;
  setIdToDelete: (id: string) => void;
}

export interface GridItemProps {
  isLoading: boolean;
  error: string;
  rows: Array<GridValidRowModel>;
}

export interface GridProps {
  navigateToEdit: (id: string) => void;
  columns: GridColDef[];
  createLink: string;
  gridItemProps: GridItemProps;
  gridDeleteConfirmationDialog: GridDeleteConfirmationDialogProps;
}

export const AppGrid = (props: GridProps) => {
  const {gridDeleteConfirmationDialog, gridItemProps, createLink, navigateToEdit} = props;
  const columns = [
    ...props.columns, {
      field: 'delete',
      headerName: 'Действия',
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            color="primary"
            aria-label="edit"
            component="span"
            onClick={navigateToEdit.bind(this, params.row.id)}
          >
            <EditIcon/>
          </IconButton>

          <IconButton
            color="primary"
            aria-label="delete"
            component="span"
            onClick={openConfirmationDialog.bind(this, params.row.id)}
          >
            <DeleteForeverOutlined/>
          </IconButton>
        </div>
      ),
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1
    }
  ];

  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = useState(false);

  const navigate = useNavigate();

  const {isLoading, error, rows} = gridItemProps;

  const {
    title,
    subTitle,
    declineText,
    handleDecline,
    handleAccept,
    acceptText,
    setIdToDelete
  } = gridDeleteConfirmationDialog;

  const openConfirmationDialog = (id: string) => {
    setIdToDelete(id);
    setConfirmationDialogOpened(true);
  }

  const handleDeleteConfirmation = () => {
    setConfirmationDialogOpened(false);
    handleAccept();
  }

  const handleCloseDeleteConfirmation = () => {
    setConfirmationDialogOpened(false);
    handleDecline();
  }

  const navigateToCreate = () => {
    navigate(createLink);
  }

  const referralTypesGrid = () => {
    if (error) {
      return <div>{error}</div>
    }
    return <div style={{height: 600}}>
      <DataGrid
        disableColumnSelector={true}
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        loading={isLoading}
        rowsPerPageOptions={[10]}
      />
    </div>
  }

  return (
    <>
      <Button
        variant={'contained'}
        color={'success'}
        style={{marginBottom: 10}}
        onClick={navigateToCreate}
      >
        Создать
      </Button>
      {referralTypesGrid()}

      <ConfirmationDialog title={title}
                          subTitle={subTitle}
                          acceptText={acceptText}
                          declineText={declineText}
                          isOpened={isConfirmationDialogOpened}
                          handleAccept={handleDeleteConfirmation}
                          handleDecline={handleCloseDeleteConfirmation}
      />
    </>
  )
}
