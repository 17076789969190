import {ReferralTypeCreateUpdate} from "../index"
import {ChangeEvent, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {getReferralType, updateReferralType} from "../../../../api/referral-type"
import {ReferralType} from "../../../../@types/referral-type/referralType"

export const ReferralTypeUpdate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const cannotGetReferralType = "Не удалось загрузить реферральный тип";
    setIsLoading(true);
    getReferralType(params.id as string)
      .then(({data, error}) => {
        if (error) {
          setError(cannotGetReferralType);
        } else {
          const referralType = data as ReferralType;
          setName(referralType.name);
          setId(referralType.id);
        }
      })
      .catch(_ => setError(cannotGetReferralType))
      .finally(() => setIsLoading(false))
  }, [params.id]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (!name || !name.trim() || name.length < 2) {
      setError("Название должно содержать хотя бы 2 символа");
    }
    setName(name);
  }

  const handleUpdate = () => {
    const cannotSave = "Не удалось сохранить";
    setIsLoading(true);
    updateReferralType({referralTypeId: id, name})
      .then(({error}) => {
        if (error) {
          setError(cannotSave)
        } else {
          navigate('/referral-types');
        }
      })
      .catch(() => setError(cannotSave))
      .finally(() => setIsLoading(false));
  }

  return <ReferralTypeCreateUpdate
    isLoading={isLoading}
    error={error}
    handleChange={handleChange}
    handleSumbit={handleUpdate}
    btnText={"Обновить"}
    name={name}
  />
}
