import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ReferralType} from "../../../../@types/referral-type/referralType";
import {getReferralBonus, updateReferralBonus} from "../../../../api/referral-bonus";
import {UpdateReferralBonus} from "../../../../@types/referral-bonus/createReferralBonus";
import {getReferralTypes} from "../../../../api/referral-type";
import {Bonus} from "../../../../@types/referral-bonus/bonus"
import {ReferralBonusCreateUpdate} from "../index"

export const ReferralBonusUpdate = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [commonError, setCommonError] = useState("");

  const [referralTypes, setReferralTypes] = useState([] as ReferralType[]);

  const [referralBonus, setReferralBonus] = useState({
    id: "",
    name: "",
    level: 1,
    value: 0,
    referralTypeId: "",
  } as UpdateReferralBonus);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getReferralTypes()
      .then(({data, error}) => {
        if (error) {
          setCommonError(error);
        } else {
          setReferralTypes(data);
        }
      })
      .catch(() => setCommonError("Не удалось получить реферальные типы"))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getReferralBonus(params.id as string)
      .then(({data, error}) => {
        if (error || !data) {
          setCommonError(error);
        } else {
          const referralType = data as Bonus;
          setReferralBonus({
            referralTypeId: referralType.referralTypeId,
            value: referralType.value,
            level: +referralType.level,
            id: referralType.id,
            name: referralType.name
          });
        }
      })
  }, [params.id]);

  const handleUpdate = () => {
    setIsLoading(true);
    updateReferralBonus(referralBonus)
      .then(() => navigate("/referral-bonus"))
      .catch(() => setCommonError("Что пошло не так"))
      .finally(() => setIsLoading(false));
  };

  return (
    <ReferralBonusCreateUpdate
      isLoading={isLoading}
      commonError={commonError}
      referralTypes={referralTypes}
      referralBonus={referralBonus}
      handleSubmit={handleUpdate}
      setReferralBonus={setReferralBonus}
    />
  );
};
