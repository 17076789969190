import { authorizationActions } from "./reducers/authorization"
import {referralTypeActions} from "./reducers/referral-type"
import {createReferralTypeActions} from "./reducers/referral-type/create-referral-type"
import { statisticActions } from "./reducers/statistic"
import { systemStateActions } from "./reducers/systemState"

export default {
  ...referralTypeActions,
  ...createReferralTypeActions,
  ...statisticActions,
  ...authorizationActions,
  ...systemStateActions
}
