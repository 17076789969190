import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectObject } from "./types/selectObject";
import { FormControlLabel, Switch } from "@mui/material";
import "./index.css";

type Props = {
  searchId?: string;
  searchLabel?: string;
  isSearch?: boolean;
  isSelect?: boolean;
  selectId?: string;
  selectLabel?: string;
  selectCollection?: SelectObject[];
  updateFilters: Function;
  isOrderBy?: boolean;
  orderByLabel?: string;
  isFastFilter?: boolean;
  fastFilterLabel?: string;
  fastFilterId?: string;
};

export const Filters = ({
  searchId,
  searchLabel,
  isSearch,
  selectId,
  isSelect,
  selectLabel,
  selectCollection,
  updateFilters,
  isOrderBy,
  orderByLabel,
  isFastFilter,
  fastFilterLabel,
  fastFilterId,
}: Props) => {
  const [queryParameters, setQueryParameters] = useSearchParams({});
  const [searchParams, setSearchParams] = useState("");
  const [select, setCurrency] = useState("");
  const [orderBy, setOrderBy] = useState<boolean>(true);
  const [fastFilter, setFastFilter] = useState<boolean>(true);

  useEffect(() => {
    setQueryParameters({
      [searchId as string]: searchParams,
      [selectId as string]: select,
      orderBy: `${orderBy}`,
      [fastFilterId as string]: `${fastFilter}`,
    });
    updateFilters(window.location.search.toString().replace("?", ""));
  }, [searchParams, select, orderBy, fastFilter, setQueryParameters]);

  return (
    <div className="container">
      <div className="filter">
        {isSearch && (
          <TextField
            id={searchId}
            name={searchId}
            label={searchLabel}
            onChange={(e) => setSearchParams(e.target.value)}
            type="search"
          />
        )}
      </div>
      <div className="filter">
        {isSelect && (
          <TextField
            id={selectId}
            select
            name={selectId}
            label={selectLabel}
            value={select}
            onChange={(e) => setCurrency(e.target.value)}
            helperText="Выберeте значение"
          >
            {selectCollection &&
              selectCollection.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            <MenuItem key="" value="">
              Не выбрано
            </MenuItem>
          </TextField>
        )}
      </div>
      {isOrderBy && (
        <div className="filter">
          <FormControlLabel
            id="orderBy"
            name="orderBy"
            control={<Switch defaultChecked />}
            label={orderByLabel}
            onChange={(e) => setOrderBy(!orderBy)}
          />
        </div>
      )}
      {isFastFilter && (
        <div className="filter">
          <FormControlLabel
            id={fastFilterId}
            name={fastFilterId}
            control={<Switch defaultChecked />}
            label={fastFilterLabel}
            onChange={(e) => setFastFilter(!fastFilter)}
          />
        </div>
      )}
    </div>
  );
};
