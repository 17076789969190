import { RewardsChart } from "../../@types/statistic/rewardChart";
import { Statistic } from "../../@types/statistic/statistic";
import { UsersGrowth } from "../../@types/statistic/usersGrowth";
import { api } from "../api-client";

export function getUsersGrowth(query: string) {
  return api.get<UsersGrowth>(
    `${process.env.REACT_APP_API_URL}ReferralUser/user_growth?${query}`
  );
}

export function getUsersRewards(query: string) {
  return api.get<RewardsChart>(
    `${process.env.REACT_APP_API_URL}api/History/chart?${query}`
  );
}

export function getAllStatistic(query: string) {
  return api.get<Statistic>(
    `${process.env.REACT_APP_API_URL}ReferralUser/statistics${query}`
  );
}
