import { Navigate } from "react-router";

export interface Props {
    children: any
}

const ProtectedRoute = ({ children }: Props

) => {
    const token = localStorage.getItem("access_token");
    
    if (token) {
        return children;
    }
    return <Navigate to="/auth" />
};

export default ProtectedRoute;