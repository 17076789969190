import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "redux"
import {createReferralType} from "../../../../api/referral-type"

interface CreateReferralTypeState {
  name: string,
  error: string | null,
  isLoading: boolean,
  isCreatedSuccessfully: boolean
}

const initialState: CreateReferralTypeState = {
  name: '',
  error: null,
  isLoading: false,
  isCreatedSuccessfully: false
}

const createReferralTypeSlice = createSlice({
  name: 'create-referral-type',
  initialState,
  reducers: {
    createReferralTypeSetInitialState: (state) => {
      state.isLoading = false;
      state.isCreatedSuccessfully = false;
      state.error = null;
      state.name = ''
    },
    createReferralTypeRequest: (state, name: PayloadAction<string>) => {
      state.isLoading = true;
      state.name = name.payload;
    },
    createReferralTypeSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
      state.name = '';
      state.isCreatedSuccessfully = true;
    },
    createReferralTypeError: (state, error: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = error?.payload || "Что-то пошло не так";
    },
    setReferralTypeName: (state, name: PayloadAction<string>) => {
      if (!name.payload || !name.payload.trim() || name.payload.length < 2) {
        state.name = name.payload
        state.error = 'Название должно содержать хотя бы 2 символа'
      } else {
        state.name = name.payload;
        state.error = null;
      }
    }
  }
})

export const {
  createReferralTypeRequest,
  createReferralTypeSuccess,
  createReferralTypeError,
  setReferralTypeName,
  createReferralTypeSetInitialState
} = createReferralTypeSlice.actions

export const createReferralTypeAsync = (name: string) => {
  return async (dispatch: Dispatch) => {
    try {
      if (!name || !name.trim()) {
        dispatch(createReferralTypeError('Название некорректно'));
      } else {
        dispatch(createReferralTypeRequest(name))
        createReferralType(name)
          .then(({error}) => {
            if (error) {
              dispatch(createReferralTypeError(error.message))
            } else {
              dispatch(createReferralTypeSuccess())
            }
          })
      }
    } catch {
      dispatch(createReferralTypeError(''))
    }
  }
}

export const createReferralTypeActions = {
  createReferralTypeAsync,
  createReferralTypeRequest,
  createReferralTypeSuccess,
  createReferralTypeError,
  setReferralTypeName,
  createReferralTypeSetInitialState
}

export const createReferralTypeReducer = createReferralTypeSlice.reducer
