import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactElement } from "react";
import SettingsIcon from "@mui/icons-material/Settings";

import "./index.css";

interface Props {
  isOpen: boolean;
  handle: Function;
  title: string;
  children: ReactElement;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};
export const CustomModal = ({ isOpen, handle, title, children }: Props) => {
  const onClose = (isOpen: boolean) => handle(isOpen);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(e) => onClose(!isOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-title">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <SettingsIcon />
          </div>

          {children}
        </Box>
      </Modal>
    </>
  );
};
