import { Status } from "../@types/referral/status";

export const UserStatuses: Status[] = [
    {
        id: "0",
        value: "Не активен"
    },
    {
        id: "1",
        value: "Активен"
    }
]
