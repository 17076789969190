import { Authorization } from '../../@types/authorization/auth';
const axios = require('axios');

export function auth(body: any) {
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    return axios.post(`${process.env.REACT_APP_SSO_URL}identity/Token`, body, config)
}

export function updateToken(form: Authorization) {
    const Querystring = require('querystring');

    const body = Querystring['stringify']({
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: 'referral offline_access',
        grant_type: 'refresh_token',
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        refresh_token: form.refreshToken
    })
    return auth(body)
}


export function getToken(form: Authorization) {
    const Querystring = require('querystring');

    const body = Querystring['stringify']({
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: 'referral offline_access',
        grant_type: 'password',
        username: form.username,
        password: form.password,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,

    })

    return auth(body)
}




