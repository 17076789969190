import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Card, CardContent, CardActions, TextField } from "@mui/material";
import { ChartData } from "../../@types/statistic/chartData";
import { ForwardedRef, ReactElement } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./index.css";
import React from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface Props {
  data: ChartData;
  chartName: string;
  children: ReactElement;
  setFrom: any;
  setTo: any;
  from: Date | null;
  to: Date | null;
}

export const Chart = React.forwardRef(
  (
    { children, data, chartName, setFrom, setTo, from, to }: Props,
    ref: ForwardedRef<any>
  ) => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: chartName,
        },
      },
    };

    return (
      <>
        {data && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Card sx={{ maxWidth: "100%", m: "1%" }}>
              <CardContent>
                <div>
                  <Line options={options} data={data!} />
                </div>
              </CardContent>
              <CardActions>
                <div className="chart-filters" ref={ref}>
                  <DesktopDatePicker
                    label="От"
                    inputFormat="dd/MM/yyyy"
                    value={from}
                    onChange={setFrom}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        className="date-picker-field"
                        {...params}
                      />
                    )}
                  />
                  <DesktopDatePicker
                    label="До"
                    inputFormat="dd/MM/yyyy"
                    value={to}
                    onChange={setTo}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        className="date-picker-field"
                        {...params}
                      />
                    )}
                  />
                  {children}
                </div>
              </CardActions>
            </Card>
          </LocalizationProvider>
        )}
      </>
    );
  }
);
