export const referralSystemStatuses = [
  {
    value: "0",
    label: "Активна",
  },
  {
    value: "1",
    label: "Отключена",
  },
  {
    value: "2",
    label: "Частично отключена",
  },
];
