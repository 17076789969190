import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit"
import {referralReducer} from "./reducers/referral-type"
import {createReferralTypeReducer} from "./reducers/referral-type/create-referral-type"
import { statisticReducer } from "./reducers/statistic";
import { authorizationReducer } from "./reducers/authorization";
import { systemStateReducer } from "./reducers/systemState";

const rootReducer = combineReducers({
    referralType: referralReducer,
    createRefferalType: createReferralTypeReducer,
    statistic: statisticReducer,
    authorization: authorizationReducer,
    systemState: systemStateReducer
});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>;
