import axios, { AxiosResponse, Method } from "axios";
import { AuthResponse } from "../@types/authorization/authResponse";
import { updateToken } from "./authorization";

class ApiClient {
  get = <TResponse>(url: string, params?: any) => {
    return this.axiosFetch<null, TResponse>(url, 'GET', undefined, params);
  }

  post = <TRequest, TResponse>(url: string, data: TRequest) => {
    return this.axiosFetch<TRequest, TResponse>(url, 'POST', data);
  }

  patch = <TRequest, TResponse>(url: string, data: TRequest) => {
    return this.axiosFetch<TRequest, TResponse>(url, 'PATCH', data);
  }

  put = <TRequest, TResponse>(url: string, data: TRequest) => {
    return this.axiosFetch<TRequest, TResponse>(url, 'PUT', data);
  }

  delete = <TResponse>(url: string) => {
    return this.axiosFetch<null, TResponse>(url, 'DELETE');
  }

  axiosFetch<TRequest, TResponse>(url: string, method?: Method, data?: TRequest, params?: any) {
    const token = localStorage.getItem('access_token')
    return axios({
      url: url,
      method: method,
      data: data,
      params: params,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response: AxiosResponse<TResponse>) => {
        return { data: response.data, error: undefined };
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          updateToken({ refreshToken: localStorage.getItem("refresh_token") as string, username: "", password: "" })
            .then((res: any) => {
              const mapData = res.data as AuthResponse
              localStorage.setItem('access_token', mapData.access_token);
              localStorage.setItem('refresh_token', mapData.refresh_token);
            }).catch((error: any) => {
              console.log(error);
              return { data: [], error: error.message }
            })
        }
        console.log(error);
        return { data: [], error: error.message }
      })
  }
}

export const api = new ApiClient();


