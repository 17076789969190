import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { patch } from "../../../api/referral";
import { SelectObject } from "../../../components/filters/types/selectObject";

import "./index.css";

interface Props {
  isOpen: boolean;
  handle: Function;
  types: SelectObject[];
  id: string;
  current: SelectObject[];
}

export const UpdateReferralUserModal = ({
  isOpen,
  handle,
  types,
  id,
  current,
}: Props) => {
  const onClose = (isOpen: boolean) => handle(isOpen);
  const [type, setType] = useState<string>();

  useEffect(() => {
    setType(current[0]?.value);
  }, [isOpen]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const update = async () => {
    if (type && id) {
      await patch({ referralTypeId: type, userId: id });
    }
    onClose(!isOpen);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(e) => onClose(!isOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-container">
            <Typography
              sx={{ mb: 3 }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Отредактируйте реферала
            </Typography>
            <TextField
              id="outlined-select-currency"
              select
              sx={{ mb: 3 }}
              label="Тип реферала"
              value={type}
              defaultValue={current}
              onChange={handleChange}
              helperText="Пожалуйста, выберите тип"
            >
              {types.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" onClick={(e) => update()}>
              Сохранить
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
