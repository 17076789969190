import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { sidebarLinks } from "./sidebar-links";
import LogoutIcon from "@mui/icons-material/Logout";
import { useActions } from "../../__store/hooks/useAction";
import SettingsIcon from "@mui/icons-material/Settings";
import { CustomModal } from "../modal";
import { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { referralSystemStatuses } from "../../constants/referralSystemStatuses";
import { getSettings, patchSystemSettings } from "../../api/settings";
import { SystemState } from "../../@types/settings/systemState";

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const { setIsLogin } = useActions();
  const [isOpen, setIsOpenModal] = useState(false);
  const [state, setState] = React.useState(0);

  const { setToastIsOpen, setToastMessage } = useActions();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    patchSystemSettings(parseInt(event.target.value)).then((res) => {
      if (!res.error) {
        setState(parseInt(event.target.value));
        setToastIsOpen(true);
        setToastMessage("Состояние системы успешно изменено");
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function logout() {
    localStorage.clear();
    navigate("/auth");
    setIsLogin(false);
  }

  function loadSystemState() {
    getSettings().then((res) => {
      setState((res.data as SystemState).systemStatus);
    });
  }

  useEffect(() => {
    loadSystemState();
  }, [state]);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {sidebarLinks.map(({ link, name }, index) => (
          <ListItem key={index} component={Link} to={"/" + link} disablePadding>
            <ListItemButton>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Панель управления реферальной системой
          </Typography>
          <div>
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => setIsOpenModal(!isOpen)}
              sx={{ mr: 2 }}
            >
              <SettingsIcon />
            </IconButton>
            <IconButton
              color="inherit"
              edge="start"
              onClick={logout}
              sx={{ mr: 2 }}
            >
              <LogoutIcon />
            </IconButton>
            <CustomModal
              isOpen={isOpen}
              handle={setIsOpenModal}
              title="Настройки"
            >
              <div>
                <br />
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Select"
                  value={state}
                  onChange={handleChange}
                  helperText="Выберите состояние реферальной системы"
                >
                  {referralSystemStatuses.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </CustomModal>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
