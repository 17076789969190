import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import {Button, TextField} from "@mui/material"
import {ChangeEventHandler, MouseEventHandler} from "react"

interface ReferralTypeCreateUpdateProps {
  isLoading: boolean;
  error: string | null;
  handleChange: ChangeEventHandler;
  handleSumbit: MouseEventHandler;
  btnText: string;
  name: string;
}

export const ReferralTypeCreateUpdate = ({
                                           isLoading,
                                           handleSumbit,
                                           handleChange,
                                           btnText,
                                           error,
                                           name
                                         }: ReferralTypeCreateUpdateProps) => {
  const outlined = 'outlined'

  if (isLoading) {
    return (<div>Загрузка...</div>);
  }

  const errors = error
    ?
    <div>
      <Divider/>
      {error}
    </div>
    : <></>

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          label={'Название реферального типа'}
          required={true}
          variant={outlined}
          value={name}
          onChange={handleChange}
        />
        <Button
          variant={outlined}
          onClick={handleSumbit}
          disabled={!!error}
        >
          {btnText}
        </Button>
      </Stack>
      {errors}
    </Stack>
  )

}
