import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CodeIcon from "@mui/icons-material/Code";
import StarRateIcon from "@mui/icons-material/StarRate";
import KeyIcon from "@mui/icons-material/Key";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getRewardsHistory } from "../../../api/referral";
import { RewardHistory } from "../../../@types/referral/rewardHistory";
import { FormControlLabel, Link, Switch } from "@mui/material";
import { getSumRewards } from "../../../api/referral";
import { ReferralUserTree } from "./referral-info-tree";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReferralUser } from "../../../@types/referral/referralUser";
import { getById } from "../../../api/referral";
import { UserStatuses } from "../../../constants/statuses";
import { Filters } from "../../../components/filters";
import { disableUser } from "../../../api/referral";

import "./index.css";

export const ReferralUserInfo = () => {
  const params = useParams();
  const [user, setUser] = useState<ReferralUser>();
  const [sumRewards, setSumRewards] = useState<number>();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [history, setHistory] = useState<RewardHistory[]>();
  const [isVisible, setIsVisible] = useState(false);
  const [isUpdateHistory, setIsUpdateHistory] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>();

  useEffect(() => {
    onLoadData();
  }, [params.id]);

  useEffect(() => {
    onLoadUser();
  }, [isDisabled]);

  function blockUser(isDisable: boolean) {
    disableUser({ id: params.id ?? "", isDisable }).then(() => {
      setIsDisabled(isDisable);
    });
  }

  function onLoadUser() {
    getById(params.id!).then((user) => {
      if (user.data) {
        setUser(user.data as ReferralUser);
        setIsDisabled((user.data as ReferralUser).isDeleted);
      }
    });
  }

  function onLoadData() {
    onLoadUser();
    getSumRewards(params.id!).then((sumRewards) => {
      setSumRewards(sumRewards.data.sum);
    });
    onLoadHistory("");
  }

  function onLoadHistory(query: string) {
    setHistory([]);
    getRewardsHistory(params.id!, query).then((_) => {
      setHistory(_.data);
    });
  }

  function updateFilters(query: string) {
    onLoadHistory(query);
    setIsUpdateHistory(!isUpdateHistory);
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <div>
        <Filters
          updateFilters={updateFilters}
          isSearch={true}
          searchId="phoneNumber"
          searchLabel="Номер телефона"
        />
        {user && (
          <Card sx={{ mb: 3 }} variant="outlined">
            <CardContent className="card-container">
              <div className="service-container">
                <Typography className="field-container" component="div">
                  Дата создания:
                  <div className="text-container">
                    {moment(user.createdAt).format("DD MMM, YYYY")}
                  </div>
                </Typography>
              </div>
              <div className="rewards-container">
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 3 }}
                >
                  <EmojiEventsIcon fontSize="medium" />
                  <div className="text-container">{sumRewards} ZAM</div>
                </Typography>
                <div className="accordion-container">
                  {history &&
                    history.map((reward) => (
                      <Accordion
                        key={reward.id}
                        expanded={expanded === reward.id}
                        onChange={handleChange(reward.id)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            {reward.reward} ZAM
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {moment(reward.createdAt).format("DD MMM, YYYY")}
                          </Typography>
                          <Link href={`${reward.fromWhomReward.id}`}>
                            От кого {reward.fromWhomReward.phoneNumber}
                          </Link>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </div>
              </div>
              <div className="general-info-container">
                <Typography
                  className="field-container"
                  variant="h5"
                  component="div"
                >
                  <BadgeIcon fontSize="medium" />
                  <div className="text-container">{user.fullName}</div>
                </Typography>
                <Typography
                  className="field-container"
                  sx={{ fontSize: 14 }}
                  component="div"
                  color="text.secondary"
                  gutterBottom
                >
                  <ContactPhoneIcon fontSize="medium" />
                  <div className="text-container">{user.phoneNumber}</div>
                </Typography>
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 1.5 }}
                >
                  <StarRateIcon fontSize="medium" />
                  <div className="text-container">{user.referralType?.name}</div>
                </Typography>
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 1.5 }}
                  variant="body2"
                >
                  <InsertLinkIcon fontSize="medium" />
                  <div className="text-container">{user.referralLink}</div>
                </Typography>
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 1.5 }}
                  variant="body2"
                >
                  <CodeIcon fontSize="medium" />
                  <div className="text-container">
                    {user.referralCode.toUpperCase()}
                  </div>
                </Typography>
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 1.5 }}
                >
                  <ErrorOutlineIcon fontSize="medium" />
                  <div className="text-container">
                    {UserStatuses.find((x) => x.id === user.status)?.value}
                  </div>
                </Typography>
                <Typography
                  className="field-container"
                  component="div"
                  sx={{ mb: 1.5 }}
                >
                  <KeyIcon fontSize="medium" />
                  <div className="text-container">{user.userId}</div>
                </Typography>
              </div>
            </CardContent>
            <CardActions>
              <Button onClick={(e) => setIsVisible(!isVisible)} size="small">
                Список приглашенных пользователей
              </Button>
              <FormControlLabel
                className="text-container"
                control={
                  <Switch
                    defaultChecked={isDisabled}
                    onChange={(e) => blockUser(!isDisabled)}
                  />
                }
                label={isDisabled ? "Заблокирован" : "Разблокирован"}
              />
            </CardActions>
          </Card>
        )}
        {user && isVisible && <ReferralUserTree user={user} />}
      </div>
    </>
  );
};
