import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import Typography from "@mui/material/Typography";

import { TreeItem, TreeItemContentProps, TreeItemProps, TreeView, useTreeItem } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { forwardRef } from "react";
import { ReferralUser } from "../../../../@types/referral/referralUser";

interface Props {
    user: ReferralUser,
}

const CustomContent = forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref,
) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
    } = useTreeItem(nodeId);

    const navigate = useNavigate();
    const icon = iconProp || expansionIcon || displayIcon;

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        event.preventDefault();
        navigate(`/Referrals/${nodeId}`, { replace: true });
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});

const CustomTreeItem = (props: TreeItemProps) => (
    <TreeItem ContentComponent={CustomContent} {...props} />
);

export const ReferralUserTree = ({ user }: Props) => {
    return (
        <>
            {user && <TreeView
                aria-label="invited users"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
                {user?.invitedUsers && user?.invitedUsers.map((first: ReferralUser) => (
                    < CustomTreeItem key={first.id} nodeId={first.id} label={first.fullName != "" ? first.fullName : first.phoneNumber} >
                        {first?.invitedUsers && first?.invitedUsers.map((second: ReferralUser) => (
                            <CustomTreeItem key={second.id} nodeId={second.id} label={second.fullName != "" ? second.fullName : second.phoneNumber}>
                                {second?.invitedUsers && second?.invitedUsers.map((third: ReferralUser) => (
                                    <CustomTreeItem key={third.id} nodeId={third.id} label={third.fullName != "" ? third.fullName : third.phoneNumber} />
                                ))}
                            </CustomTreeItem>
                        ))}
                    </CustomTreeItem>
                ))}
            </TreeView>}
        </>
    )
}
