
import { useRoutes } from 'react-router-dom';
import { ReferralTypes } from "./pages/referral-type"
import { ReferralTypeCreate } from "./pages/referral-type/referral-type-create-update/referral-type-create"
import { ReferralBonuses } from "./pages/referral-bonus"
import { ReferralBonusCreate } from "./pages/referral-bonus/referral-bonus-create-update/referral-bonus-create"
import { ReferralBonusUpdate } from "./pages/referral-bonus/referral-bonus-create-update/referral-bonus-update"
import { ReferralTypeUpdate } from "./pages/referral-type/referral-type-create-update/referral-type-update"
import { Referrals, ReferralUserInfo, Statistics, Auhorization, Users } from './pages';
import ProtectedRoute from './protectedRoutes';


export function Routes() {
  return useRoutes([
    {
      path: "/referrals",
      element: <ProtectedRoute><Referrals /></ProtectedRoute>,
    },
    {
      path: "/referrals/:id",
      element: <ProtectedRoute><ReferralUserInfo /></ProtectedRoute>,
    },
    {
      path: "/statistic",
      element: <ProtectedRoute><Statistics /></ProtectedRoute>,
    },
    {
      path: '/referral-types',
      element: <ProtectedRoute><ReferralTypes /></ProtectedRoute>
    },
    {
      path: '/referral-types/create',
      element: <ProtectedRoute> <ReferralTypeCreate /></ProtectedRoute>
    },
    {
      path: '/referral-types/:id',
      element: <ProtectedRoute><ReferralTypeUpdate /></ProtectedRoute>
    },
    {
      path: '/referral-bonus',
      element: <ProtectedRoute><ReferralBonuses /></ProtectedRoute>
    },
    {
      path: '/referral-bonus/create',
      element: <ProtectedRoute><ReferralBonusCreate /></ProtectedRoute>
    },
    {
      path: '/referral-bonus/:id',
      element: <ProtectedRoute><ReferralBonusUpdate /></ProtectedRoute>
    },
    {
      path: '/',
      element: <ProtectedRoute><Referrals /></ProtectedRoute>
    },
    {
      path: '/users',
      element: <ProtectedRoute><Users /></ProtectedRoute>
    },
    {
      path: '/auth',
      element: <Auhorization />
    }
  ]);
}
