import {useTypedSelector} from "../../__store/hooks/typedSelector"
import {useActions} from "../../__store/hooks/useAction"
import {DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid'
import {useEffect, useState} from "react"
import {Button} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {DeleteForeverOutlined} from "@mui/icons-material"
import {ConfirmationDialog} from "../../components/confirmation-dialog"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"

export const ReferralTypes = () => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'name',
      headerName: 'Название',
      type: 'string',
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'bonuses',
      headerName: 'Количество бонусов',
      valueGetter: (params: GridValueGetterParams) => params.row.bonuses.length,
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'delete',
      headerName: 'Действия',
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IconButton
            color="primary"
            aria-label="edit"
            component="span"
            onClick={navigateToEdit.bind(this, params.row.id)}
          >
            <EditIcon/>
          </IconButton>

          <IconButton
            color="primary"
            aria-label="delete"
            component="span"
            onClick={openConfirmationDialog.bind(this, params.row.id)}
          >
            <DeleteForeverOutlined/>
          </IconButton>
        </div>
      ),
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      disableColumnMenu: true
    }
  ];

  const referralTypeState = useTypedSelector(s => s.referralType);

  const [isConfirmationDialogOpened, setConfirmationDialogOpened] = useState(false);

  const [referralIdToDelete, setReferralIdToDelete] = useState('');

  const {referralTypes, error, take, isLoading, page} = referralTypeState;

  const {
    fetchReferralTypesRequestAsync,
    createReferralTypeSetInitialState,
    deleteReferralTypesRequestAsync
  } = useActions();

  const navigate = useNavigate();

  useEffect(() => {
    fetchReferralTypesRequestAsync(page, take)
  }, [page, take])

  useEffect(() => {
    createReferralTypeSetInitialState()
  })

  const navigateToCreate = () => {
    navigate('/referral-types/create');
  }

  const navigateToEdit = (id: string) => {
    navigate(`/referral-types/${id}`);
  }

  const openConfirmationDialog = (id: string) => {
    setReferralIdToDelete(id);
    setConfirmationDialogOpened(true);
  }

  const handleDeleteConfirmation = () => {
    setConfirmationDialogOpened(false);
    deleteReferralTypesRequestAsync(referralTypeState, referralIdToDelete);
  }

  const handleCloseDeleteConfirmation = () => {
    setConfirmationDialogOpened(false);
  }

  const referralTypesGrid = () => {
    if (error) {
      return <div>{error}</div>
    }
    return <div style={{height: 600}}>
      <DataGrid
        disableColumnSelector={true}
        rows={referralTypes}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        loading={isLoading}
        rowsPerPageOptions={[10]}
      />
    </div>
  }

  return (
    <>
      <Button
        variant={'contained'}
        color={'success'}
        style={{marginBottom: 10}}
        onClick={navigateToCreate}
      >
        Создать
      </Button>
      {referralTypesGrid()}

      <ConfirmationDialog title={'Подтвердите действие'}
                          subTitle={'Вы уверены, что хотите удалить реферальный тип?'}
                          isOpened={isConfirmationDialogOpened}
                          handleAccept={handleDeleteConfirmation}
                          handleDecline={handleCloseDeleteConfirmation}
      />
    </>
  )
}
