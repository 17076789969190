import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {ReferralType} from "../../../../@types/referral-type/referralType";
import {createReferralBonus} from "../../../../api/referral-bonus";
import {CreateReferralBonus} from "../../../../@types/referral-bonus/createReferralBonus";
import {getReferralTypes} from "../../../../api/referral-type";
import {ReferralBonusCreateUpdate} from "../index"

export const ReferralBonusCreate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [commonError, setCommonError] = useState("");

  const [referralTypes, setReferralTypes] = useState([] as ReferralType[]);

  const [referralBonus, setReferralBonus] = useState({
    name: "",
    level: 1,
    value: 0,
    referralTypeId: "",
  } as CreateReferralBonus);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getReferralTypes()
      .then(({data, error}) => {
        if (error) {
          setCommonError(error);
        } else {
          setReferralTypes(data);
        }
      })
      .catch(() => setCommonError("Не удалось получить реферальные типы"))
      .finally(() => setIsLoading(false));
  }, []);

  const handleCreate = () => {
    setIsLoading(true);
    createReferralBonus(referralBonus)
      .then(() => navigate("/referral-bonus"))
      .catch(() => setCommonError("Что пошло не так"))
      .finally(() => setIsLoading(false));
  };

  return (
    <ReferralBonusCreateUpdate
      isLoading={isLoading}
      commonError={commonError}
      referralTypes={referralTypes}
      referralBonus={referralBonus}
      handleSubmit={handleCreate}
      setReferralBonus={setReferralBonus}
    />
  );
};
