import {useEffect, useState} from "react"
import {deleteReferralBonus, getReferralBonuses} from "../../api/referral-bonus"
import {Bonus} from "../../@types/referral-bonus/bonus"
import {GridColDef} from "@mui/x-data-grid"
import {AppGrid} from "../../components/grid"
import {useNavigate} from "react-router-dom"

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true
  },
  {
    field: 'name',
    headerName: 'Название',
    type: 'string',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true
  },
  {
    field: 'level',
    headerName: 'Уровень',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true
  },
  {
    field: 'value',
    headerName: 'Значение',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true
  },
  {
    field: 'referralTypeId',
    headerName: 'Id реферального типа',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    disableColumnMenu: true
  }
];

interface ReferralBonusState {
  isLoading: boolean;
  error: string;
  referralBonuses: Bonus[]
}

const initialReferralBonusState: ReferralBonusState = {
  isLoading: false,
  error: '',
  referralBonuses: []
}

export const ReferralBonuses = () => {
  const [referralBonusState, setreferralBonusState] = useState(initialReferralBonusState);

  const navigate = useNavigate();

  const [idToDelete, setIdToDelete] = useState('');

  useEffect(() => {
    getReferralBonusesList();
  }, [])

  const getReferralBonusesList = () => {
    setreferralBonusState({...referralBonusState, isLoading: true});
    getReferralBonuses()
      .then(({data, error}) => {
        if (error) {
          setreferralBonusState({...referralBonusState, error: error, isLoading: false});
        } else {
          setreferralBonusState({referralBonuses: data, error: '', isLoading: false})
        }
      })
  }

  const deleteReferralBonusById = (id: string) => {
    setreferralBonusState({...referralBonusState, isLoading: true});
    deleteReferralBonus(id)
      .then(_ => {
        getReferralBonusesList();
      })
      .catch(_ => {
        console.log(_);
      })
  }

  const navigateToEdit = (id: string) => {
    navigate(`/referral-bonus/${id}`);
  }

  return (
    <AppGrid
      navigateToEdit={navigateToEdit}
      columns={columns}
      createLink={'/referral-bonus/create'}
      gridItemProps={{
        isLoading: referralBonusState.isLoading,
        rows: referralBonusState.referralBonuses,
        error: referralBonusState.error
      }}
      gridDeleteConfirmationDialog={{
        title: 'Подтвердите действие',
        subTitle: "Вы уверены, что хотите удалить реферальный бонус?",
        declineText: 'Нет',
        acceptText: 'Да',
        handleDecline: () => {
        },
        handleAccept: () => {
          deleteReferralBonusById(idToDelete);
        },
        setIdToDelete: (id) => {
          setIdToDelete(id);
        }
      }}
    />
  )
}
